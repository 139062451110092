import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { CoreService } from 'src/app/core/services/core.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClinicalRoomsService extends CoreService {
  addClinicalRoom(data){
    return this.add('clinical_rooms/v1',data)
  }

  getByBranchId(branchId){
    return this.getById('clinical_rooms/v1/list/',branchId)
  }

  getBranchesWithCustomerId(){
    return this.http
      .get<any>(environment.serverURL+'clinical_rooms/v1/list')
      .pipe(
        tap((res) => {
        }),
        catchError(this.handleError)
      );
  }

  getAllRoomsWithUserId(){
    return this.http
      .get<any>(environment.serverURL+'clinical_rooms/v1/list-user')
      .pipe(
        tap((res) => {
        }),
        catchError(this.handleError)
      );
  }
 
  deleteRoom(roomId){
    return this.delete('clinical_rooms/v1/',roomId)
  }
}
