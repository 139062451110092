import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { BaseService } from "src/app/core/services/base.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class userProfileSubject extends BaseService {
  private profileImage = new Subject<string>();
  private name = new Subject<string>();
  currentProfileImage = this.profileImage.asObservable();
  currentName = this.name.asObservable();

  constructor(private http: HttpClient) {
    super();
  }

  changeProfileImage(img: string) {
    this.profileImage.next(img);
  }

  changeName(name: string) {
    this.name.next(name);
  }

  addDocuments(data) {
    return this.http
      .post<any>(environment.serverURL + "user-documents/v1", data)
      .pipe(
        tap((res) => {}),
        catchError(this.handleError)
      );
  }
}
