import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CoreService } from "./core.service";
@Injectable({
  providedIn: "root",
})
export class ServiceCoreService extends CoreService {
  getAllServiceByBranchId(branchId): Observable<any> {
    return this.http.get<any>(
      environment.serverURL + "service/v1/list/" + branchId
    );
  }

  getAllServiceByCustomerId(): Observable<any> {
    return this.http.get<any>(environment.serverURL + "service/v1/list");
  }

  getAllServiceByUserId(): Observable<any> {
    return this.http.get<any>(environment.serverURL + "service/v1/list-user");
  }

  addService(data) {
    return this.add("service/v1", data);
  }

  deleteService(serviceid) {
    return this.delete("service/v1/", serviceid);
  }

  getService(serviceid) {
    return this.getById("service/v1/", serviceid);
  }
}
