import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranlateRtlDirctiondService {
  
  constructor(public translate: TranslateService) { }

  textDir = 'ltr'; // direction page
  languageChange: Subject<string> = new Subject<string>(); // subject object to make varible stream 
  languageType: Subject<string> = new Subject<string>();
  Translate(type) {
     this.translate.use(type);// type ar (arabic) or en 
     if (type == 'ar') this.textDir = 'rtl'; 
     else this.textDir = 'ltr';
     this.languageChange.next(this.textDir)
     this.languageType.next(type)
   }
}
