import { Injectable } from "@angular/core";
import { CoreService } from "./core.service";
import { environment } from "src/environments/environment";
import { catchError, tap } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class UserCoreService extends CoreService {
  getAllUsers() {
    return this.getAll("user/v1/list/");
  }

  getAllUsersInBranch(branchId) {
    return this.http
      .get<any>(environment.serverURL + "user/v1/list/" + branchId)
      .pipe(
        tap((res) => {}),
        catchError(this.handleError)
      );
  }

  getAllUsersInCustomer() {
    return this.http.get<any>(environment.serverURL + "user/v1/list").pipe(
      tap((res) => {}),
      catchError(this.handleError)
    );
  }

  getAllUsersByUserId() {
    return this.http.get<any>(environment.serverURL + "user/v1/list-user").pipe(
      tap((res) => {}),
      catchError(this.handleError)
    );
  }

  getAllDoctors() {
    return this.getAll("user/v1/list/doctors/");
  }

  getAllCustomerDoctors() {
    return this.getWithoutBrachId("user/v1/list/customer/doctors");
  }

  getAllBranchDoctors(branchId) {
    return this.getWithoutBrachId("user/v1/list/branch/doctors/" + branchId);
  }

  addUser(data) {
    return this.add("auth/create-user", data);
  }

  updateUser(data) {
    return this.put("user/v1", data);
  }

  updateProfileUser(data) {
    return this.put("user/v1/ProfileUser", data);
  }

  deleteUser(userId) {
    return this.delete("user/v1/", userId);
  }

  getUserById(userId) {
    return this.getById("user/v1/getUserById/", userId);
  }

  sendVerifyEmail(user) {
    return this.http.post(
      `${environment.serverURL}auth/resend-verify-email`,
      user
    );
  }
}
