import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, tap } from "rxjs/operators";
import { LoaderService } from "./loader.service";
import { BaseService } from "./base.service";
import jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: "root",
})
export class AuthService extends BaseService {
  constructor(private http: HttpClient, private loader: LoaderService) {
    super();
  }

  forgotPassword(data): Observable<any> {
    this.loader.emitLoader(true);
    return this.http
      .post<any>(environment.serverURL + "auth/forgotpassword", data)
      .pipe(catchError(this.handleError));
  }

  recoverPassword(number, userId): Observable<any> {
    this.loader.emitLoader(true);
    return this.http
      .post<any>(environment.serverURL + "auth/recoverpassword", {
        number,
        userId,
      })
      .pipe(catchError(this.handleError));
  }

  changePasswordByUserId(password, userId): Observable<any> {
    this.loader.emitLoader(true);
    return this.http
      .post<any>(environment.serverURL + "auth/change-forgotten-password", {
        password,
        userId,
      })
      .pipe(catchError(this.handleError));
  }

  register(data): Observable<any> {
    this.loader.emitLoader(true);
    return this.http
      .post<any>(environment.serverURL + "auth/signup", data)
      .pipe(catchError(this.handleError));
  }

  login(data): Observable<any> {
    return this.http
      .post<any>(environment.serverURL + "auth/signin", data)
      .pipe(
        tap((res) => {
          localStorage.setItem("token", res.data[0].idToken);
          localStorage.setItem("branchId", res.data[0].branchId);
          localStorage.setItem("name", res.data[0].name);
          localStorage.setItem("userType", btoa(res.data[0].staffType));
          localStorage.setItem("industry", btoa(res.data[0].industry));
          localStorage.setItem("userId", res.data[0].userId);
        })
      );
  }

  changePassword(data): Observable<any> {
    return this.http
      .post<any>(environment.serverURL + "auth/change-password", data)
      .pipe(
        tap((res) => {
          localStorage.setItem("token", res.data[0].idToken);
          localStorage.setItem("branchId", res.data[0].branchId);
          localStorage.setItem("name", res.data[0].name);
          localStorage.setItem("userType", btoa(res.data[0].staffType));
          localStorage.setItem("industry", btoa(res.data[0].industry));
          localStorage.setItem("userId", res.data[0].userId);
        })
      );
  }

  verify(token: string): Observable<any> {
    return this.http
      .post<any>(environment.serverURL + "auth/verify-email", { token })
      .pipe(catchError(this.handleError));
  }

  getToken() {
    return localStorage.getItem("token");
  }

  getBranchId() {
    return localStorage.getItem("branchId");
  }
  getNameOfCustomer() {
    return localStorage.getItem("name");
  }

  checkUserType(types: string[]) {
    for (const type of types) {
      if (type == atob(localStorage.getItem("userType"))) {
        return true;
      }
    }
  }

  checkIndustryType(type: string) {
    if (type == atob(localStorage.getItem("industry"))) {
      return true;
    } else {
      return false;
    }
  }

  getUserId() {
    return localStorage.getItem("userId");
  }

  getDecodedAccessToken(): any {
    try {
      let token = localStorage.getItem('token');
      return jwt_decode(token);
    } catch(Error) {
      return null;
    }
  }
}
