import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { mergeMap, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";
import { CoreService } from "./core.service";
import { S3Service } from "./s3.service";

const customerEndpoint = "customer/v1/";

@Injectable({
  providedIn: "root",
})
export class CustomerService extends CoreService {
  constructor(
    public http: HttpClient,
    public auth: AuthService,
    private s3Service: S3Service
  ) {
    super(http, auth);
  }
  getCurrentCustomer() {
    return this.getWithoutBrachId(customerEndpoint + "current");
  }

  updateCustomer(newCustomer) {
    return of({...newCustomer}).pipe(
      mergeMap((updatedData) =>
        this.s3Service.uploadFileIfPresent(updatedData, "logo")
      ),
      switchMap((updatedData) => {
        return this.add(customerEndpoint, updatedData);
      })
    );
  }

  deleteCustomerLogofromS3Bucket()
  {
    return this.http.delete(`${environment.serverURL}customer/v1/file/deleteCustomerLogo`)
  }
}
