import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { Location } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/core/services/auth.service";
import { ClientRequestsSubject } from "src/app/calendar/services/clientRequestsSubject.service";
const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};

declare var $: any;
@Component({
  selector: "app-navbar-cmp",
  templateUrl: "navbar.component.html",
  styleUrls: ["navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  private listTitles: any[];
  location: Location;
  notifications = { clientRequests: [] };
  getTotalNotifcationsLength(): number {
    return Object.values(this.notifications)
      .map((array) => array.length)
      .reduce((accumulator, length) => accumulator + length, 0);
  }
  mobile_menu_visible: any = 0;
  private nativeElement: Node;
  private toggleButton: any;
  private sidebarVisible: boolean;
  private _router: Subscription;
  lang = localStorage.getItem("lang") || "ar";
  @ViewChild("app-navbar-cmp", { static: false }) button: any;
  clientName: any;

  constructor(
    location: Location,
    private translate: TranslateService,
    private element: ElementRef,
    private router: Router,
    private translatePackage: TranslateService,
    private auth: AuthService,
    private clientRequestsSubject: ClientRequestsSubject
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
    this.Translate(this.lang);
  }
  Translate(type: string) {
    this.translatePackage.use(type); // ar or en
  }
  minimizeSidebar() {
    const body = document.getElementsByTagName("body")[0];

    if (misc.sidebar_mini_active === true) {
      body.classList.remove("sidebar-mini");
      misc.sidebar_mini_active = false;
    } else {
      setTimeout(function () {
        body.classList.add("sidebar-mini");

        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }
  hideSidebar() {
    const body = document.getElementsByTagName("body")[0];
    const sidebar = document.getElementsByClassName("sidebar")[0];

    if (misc.hide_sidebar_active === true) {
      setTimeout(function () {
        body.classList.remove("hide-sidebar");
        misc.hide_sidebar_active = false;
      }, 300);
      setTimeout(function () {
        sidebar.classList.remove("animation");
      }, 600);
      sidebar.classList.add("animation");
    } else {
      setTimeout(function () {
        body.classList.add("hide-sidebar");
        // $('.sidebar').addClass('animation');
        misc.hide_sidebar_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  ngOnInit() {
    this.handleNotifications();
    this.clientName = this.auth.getNameOfCustomer();
    //ROUTES.filter(listTitle => listTitle);
    this.listTitles = [
      // {
      //   path: "/dashboard",
      //   title: this.translate.instant("dashboard"),
      //   type: "link",
      //   icontype: "dashboard",
      // },
      {
        path: "/settings",
        title: this.translate.instant("Settings"),
        type: "sub",
        icontype: "settings",
        collapse: "settings",
        children: [
          {
            path: "business-hours",
            title: this.translate.instant("Business Hours"),
            //   ab: "BH",
          },
          {
            path: "business-staff",
            title: this.translate.instant("Business Staff"),
            //   ab: "BS",
          },
          {
            path: "business-speciality",
            title: this.translate.instant("Business Speciality"),
            //   ab: "BS",
          },
          {
            path: "business-service",
            title: this.translate.instant("Business Services"),
            //   ab: "Ser",
          },
          {
            path: "business-Settings",
            title: this.translate.instant("Business Settings"),
            //   ab: "Ser",
          },
        ],
      },
      {
        path: "/calendar",
        title: this.translate.instant("schedule"),
        type: "link",
        icontype: "date_range",
      },
      {
        path: "/billing",
        title: this.translate.instant("Billing"),
        type: "link",
        icontype: "date_range",
      },
      {
        path: "/clients",
        title: this.translate.instant("Clients"),
        type: "link",
        icontype: "date_range",
      },
      {
        path: "/doctors-requests",
        title: this.translate.instant("Doctor Requests"),
        type: "link",
        icontype: "date_range",
      },
      {
        path: "/dashboard",
        title: this.translate.instant("Dashboard"),
        type: "link",
        icontype: "date_range",
      },
      {
        path: "/purchase-order",
        title: this.translate.instant("Purchase Order"),
        type: "link",
        icontype: "date_range",
      },
      {
        path: "/billing/invoices",
        title: this.translate.instant("Invoices"),
        type: "link",
        icontype: "date_range",
      },
    ];

    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    if (body.classList.contains("sidebar-mini")) {
      misc.sidebar_mini_active = true;
    }
    if (body.classList.contains("hide-sidebar")) {
      misc.hide_sidebar_active = true;
    }
    this._router = this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        this.sidebarClose();

        const $layer = document.getElementsByClassName("close-layer")[0];
        if ($layer) {
          $layer.remove();
        }
      });
  }
  onResize(event) {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }
  sidebarOpen() {
    var $toggle = document.getElementsByClassName("navbar-toggler")[0];
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName("body")[0];
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);
    body.classList.add("nav-open");
    setTimeout(function () {
      $toggle.classList.add("toggled");
    }, 430);

    var $layer = document.createElement("div");
    $layer.setAttribute("class", "close-layer");

    if (body.querySelectorAll(".main-panel")) {
      document.getElementsByClassName("main-panel")[0].appendChild($layer);
    } else if (body.classList.contains("off-canvas-sidebar")) {
      document
        .getElementsByClassName("wrapper-full-page")[0]
        .appendChild($layer);
    }

    setTimeout(function () {
      $layer.classList.add("visible");
    }, 100);

    $layer.onclick = function () {
      //asign a function
      body.classList.remove("nav-open");
      this.mobile_menu_visible = 0;
      this.sidebarVisible = false;

      $layer.classList.remove("visible");
      setTimeout(function () {
        $layer.remove();
        $toggle.classList.remove("toggled");
      }, 400);
    }.bind(this);

    body.classList.add("nav-open");
    this.mobile_menu_visible = 1;
    this.sidebarVisible = true;
  }
  sidebarClose() {
    var $toggle = document.getElementsByClassName("navbar-toggler")[0];
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton.classList.remove("toggled");
    var $layer = document.createElement("div");
    $layer.setAttribute("class", "close-layer");

    this.sidebarVisible = false;
    body.classList.remove("nav-open");
    // $('html').removeClass('nav-open');
    body.classList.remove("nav-open");
    if ($layer) {
      $layer.remove();
    }

    setTimeout(function () {
      $toggle.classList.remove("toggled");
    }, 400);

    this.mobile_menu_visible = 0;
  }
  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }
    for (let i = 0; i < this.listTitles.length; i++) {
      if (
        this.listTitles[i].type === "link" &&
        this.listTitles[i].path === titlee
      ) {
        return this.listTitles[i].title;
      } else if (this.listTitles[i].type === "sub") {
        for (let j = 0; j < this.listTitles[i].children.length; j++) {
          let subtitle =
            this.listTitles[i].path + "/" + this.listTitles[i].children[j].path;
          if (subtitle === titlee) {
            return this.listTitles[i].children[j].title;
          }
        }
      }
    }
    return "";
  }
  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }

  logout() {
    localStorage.clear();
    localStorage.setItem("lang", this.lang);
    this.router.navigate(["sign/register"]);
  }

  handleNotifications() {
    this.clientRequestsSubject.currentRequests.subscribe((res) => {
      this.notifications.clientRequests = res;
    });
  }
}
