// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  //serverURL:'https://potential-ui.herokuapp.com/'
  //serverURL: "http://localhost:3000/sortp/",
  //serverURL: "http://54.226.214.42:3000/sortp/",
  serverURL: "https://bk.sortp.ai/sortp/",
  // clientURL:'http://localhost:4200',
  clientURL: "https://www.sortp.ai",
  s3UploadUrl: "https://s3.us-east-1.amazonaws.com/sortp-test-files",
};
