import { Routes } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";

import { LandingPageComponent } from "src/app/landing-page/landing-page.component";
import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "main",
    pathMatch: "full",
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "purchase-order",
        loadChildren:
          "./purchase-order-module/purchase-order.module#PurchaseOrderModule",
      },
      {
        path: "cash-in",
        loadChildren: "./cash-in/cash-in.module#CashInModule",
      },
      {
        path: "billing",
        loadChildren: "./billing/billing.module#BillingModule",
      },
      {
        path: "settings",
        loadChildren: "./settings-module/settings.module#SettingsModule",
      },
      {
        path: "calendar",
        loadChildren: "./calendar/calendar.module#CalendarModule",
      },
      {
        path: "dashboard",
        loadChildren: "./dashboard/dashboard.module#DashboardModule",
      },
      {
        path: "financial-dashboard",
        loadChildren:
          "./financial-dashboard/financial-dashboard.module#financialDashboardModule",
      },
      {
        path: "clients",
        loadChildren: "./clients/clients.module#ClientsModule",
      },
      {
        path: "profile",
        loadChildren: "./userpage/user.module#UserModule",
      },
      {
        path: "check-in-out",
        loadChildren: "./check-in-out/check-in-out.module#CheckInOutModule",
      },
      {
        path: "clients-requests",
        loadChildren:
          "./client-requests/client-requests.module#ClientRequestsModule",
      },
      {
        path: "doctors-requests",
        loadChildren:
          "./doctors-requests/doctors-requests.module#DoctorsRequestsModule",
      },
      {
        path: "doctors-requests",
        loadChildren:
          "./doctors-requests/doctors-requests.module#DoctorsRequestsModule",
      },
      {
        path: "referals",
        loadChildren: "./referals/referals.module#ReferalsModule",
      },
    ],
  },
  {
    path: "main",
    component: LandingPageComponent,
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "sign",
        loadChildren: "./sign/pages.module#SignModule",
      },
    ],
  },
];
