import { Component, OnInit } from '@angular/core';
import { TranlateRtlDirctiondService } from "src/app/core/services/tranlate-rtl-dirctiond.service";

@Component({
    selector: 'app-footer-cmp',
    templateUrl: 'footer.component.html'
})

export class FooterComponent implements OnInit {
    test: Date = new Date();
    [x: string]: any; // for any variable undefined
    dirictionPage = 'ltr';
    constructor(public translate: TranlateRtlDirctiondService) {

    }
    ngOnInit() { }

    checkLang() {
        return localStorage.getItem('lang') == 'en' ?  'عربي' : 'English';
    }

    changeLang(type) {

        this.Translate(type)
        if(localStorage.getItem('lang') == 'en') {
            localStorage.setItem('direction', 'rtl')
            return localStorage.setItem('lang', 'ar')
        }
        else{
            localStorage.setItem('direction', 'ltr')
            return localStorage.setItem('lang', 'en');
        }
    }
    Translate(type) {
        this.translate.Translate(type)
        window.location.reload()
    }
}
