import { CommonModule } from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";

import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { FixedpluginModule } from "./shared/fixedplugin/fixedplugin.module";
import { FooterModule } from "./shared/footer/footer.module";
import { NavbarModule } from "./shared/navbar/navbar.module";
import { SidebarModule } from "./sidebar/sidebar.module";

import { MatNativeDateModule } from "@angular/material/core";
import { AppRoutes } from "./app.routing";
import { MaterialModule } from "./core/modules/material.module";

// translate
import { BrowserModule } from "@angular/platform-browser";
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
  NgxUiLoaderConfig,
  NgxUiLoaderHttpModule,
  NgxUiLoaderModule,
  PB_DIRECTION,
  POSITION,
  SPINNER,
} from "ngx-ui-loader";
import { BussinessTutorialComponent } from "./bussiness-tutorial/bussiness-tutorial.component";
import { TokenInterceptorService } from "./core/interceptors/token-interceptor.service";
import { BaseService } from "./core/services/base.service";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { BusinessHoursModule } from "./settings-module/branches/branch-details/bussiness-hours/bussiness-hours.module";
import { QRCodeModule } from "angularx-qrcode";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/translate/", ".json");
}
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsPosition: POSITION.bottomRight,
  fgsSize: 60,
  bgsOpacity: 0.5,
  overlayColor: "transparent",
  fgsType: SPINNER.cubeGrid, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
};
const arrayOfExcludeApi = [
  "http://localhost:3000/sortp/clientRequests/v1/listUnConfirmed",
  "http://54.226.214.42:3000/sortp/clientRequests/v1/listUnConfirmed",
  "https://bk.sortp.ai/sortp/clientRequests/v1/listUnConfirmed",
];
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxUiLoaderHttpModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
    }),
    HttpClientModule,
    ReactiveFormsModule,
    MaterialModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    BusinessHoursModule,
    FixedpluginModule,
    QRCodeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({
      exclude: arrayOfExcludeApi,
      showForeground: true,
    }),
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    LandingPageComponent,
    BussinessTutorialComponent,
  ],
  providers: [
    MatNativeDateModule,
    TranslateService,
    BaseService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
