import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpResponse } from "@angular/common/http";
import { AuthService } from "../services/auth.service";
import { catchError, tap } from "rxjs/operators";
import { BaseService } from "../services/base.service";

const AWS_URL = "amazonaws.com";
@Injectable({
  providedIn: "root",
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(req, next) {
    let newRequest = req;
    const baseService = this.injector.get(BaseService);
    if (req.url.indexOf(AWS_URL) === -1) {
      const authService = this.injector.get(AuthService);
      newRequest = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authService.getToken()}`,
          lang: `${localStorage.getItem("lang")}`,
        },
      });
    }

    return next.handle(newRequest).pipe(catchError(baseService.handleError));
  }
}
