import { ChangeDetectorRef, Component, OnInit, Renderer2 } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import PerfectScrollbar from "perfect-scrollbar";
import { UserService } from "../calendar/services/user.service";
import { userProfileSubject } from "../userpage/services/userService.service";
import { AuthService } from "./../core/services/auth.service";
import { ClientRequestsService } from "../calendar/services/clientRequests.service";
import { ClientRequestsSubject } from "../calendar/services/clientRequestsSubject.service";
import { interval } from "rxjs";
import { startWith, switchMap } from "rxjs/operators";
import { CustomerService } from "../core/services/customer.service";
import { PackageService } from "../core/services/packages.service";

declare const $: any;

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

@Component({
  selector: "app-sidebar-cmp",
  templateUrl: "sidebar.component.html",
  styleUrls: ["sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  lang = localStorage.getItem("lang") || "ar";
  name = localStorage.getItem("name");
  userId = localStorage.getItem("userId");
  userImage;
  userName;
  customer;
  isSidebarMini = false;
  notifications = { clientRequests: [] };
  AdminReceptionRoutes;
  getTotalNotifcationsLength(): number {
    return Object.values(this.notifications)
      .map((array) => array.length)
      .reduce((accumulator, length) => accumulator + length, 0);
  }
  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private userAdmin: UserService,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    private clientRequestsService: ClientRequestsService,
    private clientRequestsSubject: ClientRequestsSubject,
    private userSubject: userProfileSubject,
    private renderer: Renderer2,
    private customerService: CustomerService,
    private packageService: PackageService
  ) {
    this.Translate(this.lang);
    this.userSubject.currentProfileImage.subscribe((userImage) => {
      this.userImage = userImage;
    });
    this.userSubject.currentName.subscribe((name) => {
      this.userName = name;
    });
  }
  Translate(type: string) {
    this.translate.use(type); // ar or en
  }

  public menuItems = [
    {
      path: "",
      title: this.translate.instant("Dashboards"),
      type: "sub",
      icontype: "dashboard",
      collapse: "dashboard",
      children: [
        {
          path: "/dashboard",
          title: this.translate.instant("Dashboard"),
          type: "link",
          icontype: "dashboard",
          disabled: false,
        },
        // {
        //   path: "/financial-dashboard",
        //   title: this.translate.instant("Financial Dashboard"),
        //   // type: "sub",
        //   type: "link",
        //   icontype: "dashboard",
        //   collapse: "dashboard",
        //   disabled: false,
        // },
      ],
    },

    {
      path: "/calendar",
      title: this.translate.instant("schedule"),
      type: "link",
      icontype: "date_range",
      disabled: false,
    },

    {
      path: "",
      title: this.translate.instant("Finance"),
      type: "sub",
      icontype: "attach_money",
      collapse: "attach_money",
      children: [
        {
          path: "/billing/invoices",
          title: this.translate.instant("Invoices"),
          type: "link",
          icontype: "receipt",
          collapse: "attach-invoices",
          disabled: false,
        },
      ],
    },
    {
      path: "/clients",
      title: this.translate.instant("Clients"),
      type: "link",
      icontype: "people",
      collapse: "people",
      disabled: false,
    },
    {
      path: `/check-in-out/${this.userId}`,
      title: this.translate.instant("Attendance"),
      type: "link",
      icontype: "where_to_vote",
      disabled: false,
    },
    {
      path: "/settings/business-Settings",
      title: this.translate.instant("Management"),
      // type: "sub",
      type: "link",
      icontype: "settings",
      collapse: "settings",
      disabled: false,
    },
    {
      path: "/doctors-requests",
      title: this.translate.instant("Doctors Requests"),
      // type: "sub",
      type: "link",
      icontype: "assignment",
      collapse: "assignment",
      disabled: false,
    },
    {
      path: "",
      title: this.translate.instant("CRM"),
      type: "sub",
      icontype: "trending_up",
      collapse: "CRM",
      children: [
        {
          path: "/referals",
          title: this.translate.instant("Referrals"),
          type: "link",
          icontype: "people",
          disabled: false,
        },
      ],
    },
  ];
  ps: any;
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    // this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.ObserveBodyChanges();
    this.handleNotifications();
    this.userAdmin
      .getUserById(localStorage.getItem("userId"))
      .subscribe((user) => {
        this.userImage = user.data[0].profilePicture;
        this.userSubject.changeProfileImage(this.userImage);
        this.userName = user.data[0].name;
        this.userSubject.changeName(this.userName);
      });

    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>(
        document.querySelector(".sidebar .sidebar-wrapper")
      );
      this.ps = new PerfectScrollbar(elemSidebar);
    }

    if (
      this.authService.checkUserType(["Head-Of-Department"]) ||
      this.authService.checkUserType(["Receptionist"]) ||
      this.authService.checkUserType(["General-Admin"]) ||
      this.authService.checkUserType(["HR-Manager"]) ||
      this.authService.checkUserType(["CEO"])
    ) {
      this.AdminReceptionRoutes = [
        {
          path: "/purchase-order",
          title: this.translate.instant("Purchase Order"),
          type: "link",
          icontype: "sell",
          collapse: "attach_purchaseOrder",
          disabled: false,
        },
        {
          path: "/cash-in",
          title: this.translate.instant("Wallet"),
          type: "link",
          icontype: "wallet",
          collapse: "attach_CashIn",
          disabled: false,
        },
      ];

      this.AdminReceptionRoutes.forEach((element) => {
        this.menuItems[2].children.push(element);
      });
    }

    this.checkThePackage();
  }

  checkDashboardsVisibility(menuitem): boolean {
    const isHeadOfDepartment = this.authService.checkUserType([
      "Head-Of-Department",
    ]);
    const isSuperAdmin = this.authService.checkUserType(["General-Admin"]);
    const isHR = this.authService.checkUserType(["HR-Manager"]);
    const isCEO = this.authService.checkUserType(["CEO"]);
    return (
      menuitem.title === "Dashboards" &&
      (isHeadOfDepartment || isSuperAdmin || isHR || isCEO)
    );
  }

  checkThePackage() {
    this.customerService.getCurrentCustomer().subscribe((res) => {
      this.packageService.changePackage(res?.package);
      if (!res?.package?.packageCheckInOut) {
        const attendanceLink = this.menuItems.find(
          (item) => item.title === "Attendance"
        );
        attendanceLink.icontype = "lock";
        attendanceLink.disabled = true;
      }
      if (!res?.package?.packageDoctorsRequests) {
        const doctorsRequestsLink = this.menuItems.find(
          (item) => item.title === "Doctors Requests"
        );
        doctorsRequestsLink.icontype = "lock";
        doctorsRequestsLink.disabled = true;
      }
      if (
        !res?.package?.packageWallet &&
        (this.authService.checkUserType(["Head-Of-Department"]) ||
          this.authService.checkUserType(["Receptionist"]) ||
          this.authService.checkUserType(["General-Admin"]))
      ) {
        const walletLink = this.AdminReceptionRoutes.find(
          (item) => item.title === "Wallet"
        );
        walletLink.icontype = "lock";
        walletLink.disabled = true;
      }
    });
  }

  ObserveBodyChanges() {
    const body = document.getElementsByTagName("body")[0];
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          // Handle changes to the body element's class attribute
          this.isSidebarMini = body.classList.contains("sidebar-mini");
        }
      });
    });

    // Start observing changes to the body element
    observer.observe(body, { attributes: true });
  }

  handleNotifications(): void {
    interval(10 * 60 * 1000)
      .pipe(
        startWith(0), // Emit initial value of 0 to trigger the first request immediately
        switchMap(() =>
          this.clientRequestsService.getClientUnconfirmedRequests()
        )
      )
      .subscribe((res) => {
        if (res.data) {
          res.data.forEach((item) => {
            this.notifications.clientRequests.push(item);
          });
        }
        this.clientRequestsSubject.changeRequests(res.data);
      });
  }

  getUrlFromFile(file) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      URL.createObjectURL(file)
    );
  }

  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  transaltePurchaseOrder() {
    return this.translate.instant("Purchase Order");
  }
}
