import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AuthService } from "src/app/core/services/auth.service";
import { BaseService } from "src/app/core/services/base.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BusinessHoursService extends BaseService {
  constructor(
    private http: HttpClient,
    private loader: LoaderService,
    private auth: AuthService
  ) {
    super();
  }

  getAllBusinessHour(branchId): Observable<any> {
    return this.http
      .get<any>(
        environment.serverURL + "branch-business-hour/v1/list/" + branchId
      )
      .pipe(
        tap((res) => {}),
        catchError(this.handleError)
      );
  }

  getBranchesBusinessHours(branches): Observable<any> {
    const branchesQueryParam = branches.join(",");

    const url = `${environment.serverURL}branch-business-hour/v1/branches-list?branches=${branchesQueryParam}`;

    return this.http.get<any>(url).pipe(
      tap((res) => {
        // Handle the response if needed
      }),
      catchError(this.handleError)
    );
  }

  addBranchHour(data) {
    return this.http
      .post<any>(environment.serverURL + "branch-business-hour/v1", data)
      .pipe(
        tap((res) => {}),
        catchError(this.handleError)
      );
  }
}
