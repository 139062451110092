import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CoreService } from "./core.service";
@Injectable({
  providedIn: "root",
})
export class ContactService {
  private httpClient: HttpClient;
  constructor( handler: HttpBackend) { 
    this.httpClient = new HttpClient(handler);
 }

    sendMail(data):Observable<any>{
        return this.httpClient.post(`${environment.serverURL}/potential/v1/contactus`,data)
      }
}
