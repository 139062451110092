import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {  Router } from "@angular/router";
import Swal from "sweetalert2";
import { AuthService } from "../core/services/auth.service";
import { ContactService } from "../core/services/contact.service";
import * as AOS from 'aos';


@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.css"],
})
export class LandingPageComponent implements OnInit {
  
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  nameFormControl = new FormControl('', [
    Validators.required,
  ]);

  messageFormControl = new FormControl('', [
    Validators.required
  ])
  
  features = [
    "Appointment Management",
    "Billing",
    "Accounting and Financial Reports",
    "Medical Record",
    "Inventory and Supplies Management",
    "HR Management",
  ];

  packages = [
    {
      name: "Individual",
      image: "./assets/img/landing-page/individual.svg",
      imageAlt: "individual package",
      price: 12,
      saveAnnually: 24,
      description:
        "For individuals or small teams needing customization and advanced features",
      includes: [
        "Appointment Management",
        "Accounting and Financial Reports",
        "Billing",
      ],
      features: {
        "Appointment Management": true,
        Billing: true,
        "Accounting and Financial Reports": true,
        "Medical Record": false,
        "Inventory and Supplies Management": false,
        "HR Management": false,
      },
    },
    {
      name: "Poly +",
      image: "./assets/img/landing-page/poly.webp",
      imageAlt: "poly package",
      price: 25,
      saveAnnually: 24,
      description: "For teams and businesses with additional scheduling needs",
      includes: [
        "Medical Record",
        "Inventory and Supplies Management",
        "HR Management",
      ],
      features: {
        "Appointment Management": true,
        Billing: true,
        "Accounting and Financial Reports": true,
        "Medical Record": true,
        "Inventory and Supplies Management": true,
        "HR Management": false,
      },
    },
    {
      name: "Enterprise",
      image: "./assets/img/landing-page/enterprise.svg",
      imageAlt: "enterprise package",
      price: 50,
      saveAnnually: 24,
      description:
        "For large businesses with advanced security, control, and support needs.",
      includes: ["Advanced reports", "Customer satisfaction measurement tools"],
      features: {
        "Appointment Management": true,
        Billing: true,
        "Accounting and Financial Reports": true,
        "Medical Record": true,
        "Inventory and Supplies Management": true,
        "HR Management": true,
      },
    },
  ];

  constructor(private authService:AuthService,private router : Router , private contactService:ContactService) {
    if(authService.getToken()) router.navigate(['calendar'])
  }

  ngOnInit(): void {
    AOS.init();

  }

  send() {
    this.contactService.sendMail({
      name: this.nameFormControl.value,
      email: this.emailFormControl.value,
      comment: this.messageFormControl.value
    }).subscribe(res => {
      Swal.fire(
        'Thank You !',
        'Your message has been sent successfully!',
        'success'
      )
    })
  }

}
