import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { mergeMap, switchMap } from "rxjs/operators";
import { S3Service } from "src/app/core/services/s3.service";
import { UserCoreService } from "src/app/core/services/user.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private adminUser: UserCoreService,
    private s3Service: S3Service,
    public http: HttpClient
  ) {}

  getAllUsers() {
    return this.adminUser.getAllUsers();
  }

  getAllUsersByBranch(branchId) {
    return this.adminUser.getAllUsersInBranch(branchId);
  }

  getAllUsersInCustomer() {
    return this.adminUser.getAllUsersInCustomer();
  }

  getAllUsersByUserId() {
    return this.adminUser.getAllUsersByUserId();
  }

  getAllDoctors() {
    return this.adminUser.getAllDoctors();
  }

  getAllCustomerDoctors() {
    return this.adminUser.getAllCustomerDoctors();
  }

  getAllBranchDoctors(branchId) {
    return this.adminUser.getAllBranchDoctors(branchId);
  }

  addUser(data) {
    return this.adminUser.addUser(data);
  }

  updateUser(data) {
    if (data.profilePicture) {
      return of(data).pipe(
        mergeMap((updatedData) =>
          this.s3Service.uploadFileIfPresent(updatedData, "profilePicture")
        ),
        switchMap((updatedData) => {
          return this.adminUser.updateProfileUser({ ...updatedData });
        })
      );
    } else {
      return this.adminUser.updateProfileUser(data);
    }
  }

  updateStaffUser(data) {
    return this.adminUser.updateUser(data);
  }

  deleteUser(userId) {
    return this.adminUser.deleteUser(userId);
  }

  getUserById(userId) {
    return this.adminUser.getUserById(userId);
  }

  deleteProfileImagefromS3Bucket(userId) {
    return this.http.delete(
      `${environment.serverURL}user/v1/file/deleteProfilePicture/` + userId
    );
  }

  sendVerifyEmail(user) {
    return this.adminUser.sendVerifyEmail(user);
  }
}
