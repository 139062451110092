import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { CoreService } from "src/app/core/services/core.service";

@Injectable({
  providedIn: "root",
})
export class ClientRequestsService extends CoreService {
  getClientRequests() {
    return this.getWithoutBrachId("clientRequests/v1/list");
  }

  getClientUnconfirmedRequests() {
    return this.getWithoutBrachId("clientRequests/v1/listUnConfirmed");
  }

  deleteAppointment(requestId) {
    return this.delete("clientRequests/v1/deleteAppointment/", requestId);
  }

  confirmRequest(requestId) {
    return this.put("clientRequests/v1/ConfirmAppointment", {
      data: requestId,
    });
  }
}
