import { Injectable } from "@angular/core";
import { CoreService } from "./core.service";

@Injectable({
  providedIn: "root",
})
export class SpecialityService extends CoreService {
  addSpeciality(data) {
    let body = {
      ...data,
      branchId: this.brachIdStorage,
    };
    return this.add("speciality/v1", body);
  }
  getAllSpeciality() {
    return this.getWithoutBrachId("speciality/v1/list");
  }
  deleteSpeciality(id) {
    return this.delete("speciality/v1/", id);
  }
}
