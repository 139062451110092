import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
declare const $: any;

export class BaseService {
  router: Router;
  template= '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
      '</div>'

  public handleError(err: any) {
    let errorMessage: any;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain reasons as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${JSON.stringify(
        err
      )}`;
      if (err.status == 401) {
            localStorage.clear();
            localStorage.setItem('lang','en')
            Swal.fire({
              icon: "warning",
              title: "Oops...",
              text: err.error.errorMsg,
            })
            window.location.assign(`${environment.clientURL}/#/sign/register`)

      }
      else if(err.error.errorMsg !='ERROR_CHANGE_TEMP_PASSWORD'){
        $.notify({
          icon: 'notifications',
          message: err.error.errorMsg
      }, {
          type: 'warning',
          timer: 2500,
          placement: {
              from: 'top',
              align:'right',
          },
          
          template: this.template
      });
      }
    }
    console.error(err);
    return throwError(errorMessage);
  }

  showNotification(from: any, align: any , type:any , msg:any) {


    $.notify({
        icon: 'notifications',
        message: msg
    }, {
        type: type,
        timer: 3000,
        placement: {
            from: from,
            align: align
        },
        template: this.template
    });
}
}
