import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { CoreService } from "src/app/core/services/core.service";


@Injectable({
    providedIn: 'root'
})

export class ClientRequestsSubject 
    {
    private requests = new BehaviorSubject<any[]>([]);
    currentRequests = this.requests.asObservable();


    constructor(){}
    
    changeRequests(requests: any[]) {
        this.requests.next(requests);
    }
}