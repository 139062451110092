import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import Swal from "sweetalert2";
import { MatDialogRef } from "@angular/material/dialog";
import { ServiceCoreService } from "../core/services/service.service";
import { Router } from "@angular/router";
import { SpecialityService } from "../core/services/speciality.service";
import { ClinicalRoomsService } from "../settings-module/services/clinical-rooms.service";
import { BusinessHoursService } from "../settings-module/services/business-hours.service";
import { DayDetails } from "../settings-module/settings.model";

@Component({
  selector: "bussiness-tutorial",
  templateUrl: "./bussiness-tutorial.component.html",
  styleUrls: ["./bussiness-tutorial.component.css"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class BussinessTutorialComponent implements OnInit {
  serviceForm: FormGroup;
  specialityForm: FormGroup;
  clinicalRoomForm: FormGroup;
  faildProcess = 0;
  dirictionPage: string;
  businessDays:DayDetails[] = [];
  branchId = localStorage.getItem('branchId');
  constructor(
    private fb: FormBuilder,
    private businessService: ServiceCoreService,
    public dialog: MatDialogRef<BussinessTutorialComponent>,
    private router:Router,
    private specialityService: SpecialityService,
    private clinicalRoomService : ClinicalRoomsService,
    private businessHoursService: BusinessHoursService
  ) {}

  ngOnInit() {
    this.dialog.afterClosed().subscribe(next=>{
      this.router.navigate(['calendar'])
    })
    this.dirictionTranslate()

    this.serviceForm = this.fb.group({
      name: ["", [Validators.required, Validators.minLength(3)]],
      duration: ["", Validators.required],
      cost: [0, Validators.required],
    });

    this.specialityForm =  this.fb.group({
      name: ["", [Validators.required]],
    });  

    this.clinicalRoomForm = this.fb.group({
      name: ["", [Validators.required]],
      description:["",[Validators.required]],
      branchId:[this.businessService.brachIdStorage]
    })

    this.businessHoursService.getAllBusinessHour(this.branchId).subscribe((res) => {
      res.data.forEach(element => {
        this.businessDays.push(element)
      })
    });
    
  }

  addService() {
    if (this.serviceForm.valid) {
      let BranchId = this.businessService.brachIdStorage;
      let data = {
        branchId: BranchId,
        name: this.serviceForm.value.name,
        cost: this.serviceForm.value.cost,
        duration: this.serviceForm.value.duration,
        preprationTime: 0,
        state: 1,
      };
      this.businessService.addService(data).subscribe((res) => {
        this.faildProcess++;
      });
    }
  }

  addSpeciality(){
    if(this.specialityForm.valid){
      return this.specialityService
      .addSpeciality(this.specialityForm.value)
      .subscribe(res=>{            
        this.faildProcess++;
      });
    }
  }

  addClinicalRoom(){
    if(this.clinicalRoomForm.valid){
      return this.clinicalRoomService.addClinicalRoom(this.clinicalRoomForm.value).subscribe(res=> this.faildProcess++)
    }
  }
  finish() {
    this.dialog.close();
  }
  dirictionTranslate() {     
      if (localStorage.getItem('lang') == "ar") {
        this.dirictionPage = 'rtl';
       
      } else {
        this.dirictionPage = 'ltr';
      
      }
    }
}
