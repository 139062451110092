import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/core/modules/material.module';
import { BussinessHoursComponent } from './bussiness-hours.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BussinessTutorialComponent } from 'src/app/bussiness-tutorial/bussiness-tutorial.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';





@NgModule({
    declarations: [
        BussinessHoursComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        MaterialModule,
        ReactiveFormsModule
    ],
    exports: [BussinessHoursComponent]
})
export class BusinessHoursModule { }
