import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { catchError, tap } from "rxjs/operators";
import { CoreService } from "src/app/core/services/core.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { AppointmentModel } from "src/app/calendar/model/appointment.model";
moment.locale("en-gb");

@Injectable({
  providedIn: "root",
})
export class PackageService extends CoreService {
  private PackageSubject = new BehaviorSubject<any>(null);
  currentPackage = this.PackageSubject.asObservable();

  changePackage(packages) {
    this.PackageSubject.next(packages);
  }
}
