import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TranlateRtlDirctiondService } from "src/app/core/services/tranlate-rtl-dirctiond.service";
import { BusinessHoursService } from '../../../services/business-hours.service';
import { DayDetails } from '../../../settings.model';

@Component({
  selector: 'app-bussiness-hours',
  templateUrl: './bussiness-hours.component.html',
  styleUrls: ['./bussiness-hours.component.css']
})
export class BussinessHoursComponent implements OnInit {
  dirictionPage = '';
  lang = localStorage.getItem('lang') || 'ar';
  businessForm: FormGroup;
  days: FormArray;
  colorOfCard = ''
  displayTitle = ''
  boxShadow = ''
  fontSize = ''
  direction = "ltr";
  constructor(
    private businessHoursService: BusinessHoursService,
    private fb: FormBuilder,
    private translate: TranlateRtlDirctiondService,
    private translatePackage: TranslateService

  ) { }
  
  @Input() businessDays: DayDetails[] = []

  /**
    * @description Get the defaultSchedule form OR the submitted schedule , then create the form with its values
  */
  Translate(type: string) {
    this.translatePackage.use(type);// ar or en
  }
  ngOnInit() {
    if (this.lang == 'ar') {
      this.dirictionPage = 'rtl'
    }
    else {
      this.dirictionPage = 'ltr'
    }
    this.Translate(this.lang)
    this.dirictionTranslate()
    this.decideTemplate();
    this.buildSchedule();
    this.direction = this.getDirection();
  }

  async buildSchedule() {

      this.businessForm = this.fb.group({
        days: this.fb.array(this.businessDays.map(day => this.createDay(day)))
      });
      this.days = this.businessForm.get('days') as FormArray;
    
  }

  getDirection() {
    return localStorage.getItem("lang") == "ar" ? "rtl" : "ltr";
  }

  /**
    * @description Applying value to formgroup
  */
  createDay(day: DayDetails): FormGroup {

    return this.fb.group({
      weekDay: [day.weekDay],
      state: [day.state],
      startTime: [day.startTime],
      endTime: [day.endTime],
      id: [day.id],
      customerId: [day.customerId],
      branchId: [day.branchId]
    });
  }

  /**
  * @description Managing the Validators based on the toggle Flag
  */
  toggleDay(flag: boolean, i: number) {
    if (flag) {
      this.days.at(i).get('startTime').setValidators(Validators.required);
      this.days.at(i).get('endTime').setValidators(Validators.required);
      this.days.at(i).get('startTime').updateValueAndValidity();
      this.days.at(i).get('endTime').updateValueAndValidity();
    } else {
      this.days.at(i).get('startTime').clearValidators();
      this.days.at(i).get('endTime').clearValidators();
      this.days.at(i).get('startTime').updateValueAndValidity();
      this.days.at(i).get('endTime').updateValueAndValidity();
    }
  }

  /**
  * @description Applying a specific day to all the days
  */
  applyToAll(day: DayDetails) {
    for (let i = 0; i < this.days.length; i++) {
      this.days.at(i).get('startTime').setValue(day.startTime);
      this.days.at(i).get('endTime').setValue(day.endTime);
      this.days.at(i).get('state').setValue(day.state);
    }
  }

  save() {
    this.businessHoursService.addBranchHour(this.businessForm.value.days).subscribe((res) => {
    })

  }

  dirictionTranslate() {
    this.translate.languageChange.subscribe((res) => {
      //response dependence on change language and if happened any change it will be change in the same time
      this.dirictionPage = res
    })
  }

  decideTemplate() {
    if (localStorage.getItem('tutorial') == null) {
      this.colorOfCard = 'transparent'
      this.displayTitle = 'none'
      this.boxShadow = 'none'
      this.fontSize = '18'
    }
  }
}
