import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { BaseService } from "./base.service";

const s3Endpoint = "s3/v1/";

@Injectable({
  providedIn: "root",
})
export class S3Service extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getUploadLinks(numberOfLinks: number, contentType: string): Observable<any> {
    return this.http
      .get<any>(environment.serverURL + s3Endpoint + "upload/", {
        params: new HttpParams()
          .set("numberOfLinks", numberOfLinks.toString())
          .set("contentType", contentType.split("/")[0]),
      })
      .pipe(
        tap((res) => {}),
        catchError(this.handleError)
      );
  }

  private getFormData(payload, file) {
    const formData = new FormData();
    formData.append("bucket", payload.bucket);
    formData.append("X-Amz-Algorithm", payload["X-Amz-Algorithm"]);
    formData.append("X-Amz-Credential", payload["X-Amz-Credential"]);
    formData.append("X-Amz-Date", payload["X-Amz-Date"]);
    formData.append("key", payload.key);
    formData.append("Policy", payload.Policy);
    formData.append("X-Amz-Signature", payload["X-Amz-Signature"]);

    formData.append("content-type", file.type);
    formData.append("file", file);
    return formData;
  }

  uploadFile(payload, file): Observable<any> {
    const formData = this.getFormData(payload, file);
    return this.http.post<any>(environment.s3UploadUrl, formData).pipe(
      tap((res) => {}),
      catchError(this.handleError)
    );
  }

  uploadSingleFile(file): Observable<any> {
    return this.getUploadLinks(1, file.type).pipe(
      mergeMap((uploadLinksRes) => {
        const fields = uploadLinksRes.data[0].fields;
        return this.uploadFile(fields, file).pipe(
          map(() => fields.key.split("/")[1])
        );
      })
    );
  }
  private extractS3ObjectKey(viewLink: string): string {
    return viewLink.split("/")[4].split("?")[0];
  }

  uploadFileIfPresent(updatedData, key): Observable<any> {
    if (typeof updatedData[key] !== "string") {
      return this.uploadSingleFile(updatedData[key]).pipe(
        map((fileUuid) => {
          updatedData[key] = fileUuid;
          return updatedData;
        })
      );
    } else {
      if (updatedData[key] !== "")
        updatedData[key] = this.extractS3ObjectKey(updatedData[key]);
    }
    return of(updatedData);
  }
}
