import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { LoaderService } from "./core/services/loader.service";
import { TranslateService, TranslationChangeEvent } from "@ngx-translate/core";
import { TranlateRtlDirctiondService } from "./core/services/tranlate-rtl-dirctiond.service";

@Component({
  selector: "app-my-app",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  [x: string]: any; // for any variable undefined
  private _router: Subscription;
  isLoading: boolean = false;
   currentLanguage = localStorage.getItem("lang") || 'en'
  constructor(
    private router: Router,
    private loader: LoaderService,
    public translate: TranslateService,
    public translationService : TranlateRtlDirctiondService  ) {
    
    this.Translate(this.currentLanguage);
  }

  ngOnInit() {
    this.setDefaultLang();
    this._router = this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        const body = document.getElementsByTagName("body")[0];
        const modalBackdrop =
          document.getElementsByClassName("modal-backdrop")[0];
        if (body.classList.contains("modal-open")) {
          body.classList.remove("modal-open");
          modalBackdrop.remove();
        }
      });
  }

  setDefaultLang() {
    this.translate.addLangs(["en", "ar"]);
    if(localStorage.getItem("lang") == null) {
      localStorage.setItem("lang" , 'en')
    }
  }

  Translate(type: string) {
    this.translate.use(type)
  }
}
