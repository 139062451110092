import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, tap } from "rxjs/operators";
import { AuthService } from "src/app/core/services/auth.service";
import { BaseService } from "src/app/core/services/base.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CoreService extends BaseService {
  constructor(
    public http: HttpClient,
    public auth: AuthService,
  ) {
    super();
  }

  brachIdStorage = this.auth.getBranchId();

  protected getAll(endPonit) {
    return this.http
      .get<any>(environment.serverURL + endPonit + this.brachIdStorage)
      .pipe(
        tap((res) => {
        }),
        catchError(this.handleError)
      );
  }
  protected getWithoutBrachId(endPonit) {
    return this.http
      .get<any>(environment.serverURL + endPonit )
      .pipe(
        tap((res) => {
        }),
        catchError(this.handleError)
      );
  }
  protected add(endPonit, data) {
    return this.http
      .post<any>(environment.serverURL + endPonit, data).pipe(catchError(this.handleError))
     
  }
  protected put(endPonit, data) {
    return this.http
      .put<any>(environment.serverURL + endPonit, data)
      .pipe(catchError(this.handleError));
  }

  protected delete(endPonit, id) {
    return this.http
      .delete<any>(environment.serverURL + endPonit + id)
  }

  protected getById(endPonit, id) {
    return this.http
      .get<any>(environment.serverURL + endPonit + id)
      .pipe(
        tap((res) => {
        }),
        catchError(this.handleError)
      );
  }
}
