import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { catchError, mergeMap, switchMap, tap } from "rxjs/operators";
import { AuthService } from "src/app/core/services/auth.service";
import { BaseService } from "src/app/core/services/base.service";
import { S3Service } from "src/app/core/services/s3.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BranchesService extends BaseService {
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private s3Service: S3Service
  ) {
    super();
  }

  getAllBranches() {
    return this.http.get<any>(environment.serverURL + "branch/v1/list/").pipe(
      tap((res) => {}),
      catchError(this.handleError)
    );
  }

  getAllCustomerBranches() {
    return this.http
      .get<any>(environment.serverURL + "branch/v1/list/BranchesCustomer")
      .pipe(
        tap((res) => {}),
        catchError(this.handleError)
      );
  }

  getAllActiveBranches() {
    return this.http
      .get<any>(environment.serverURL + "branch/v1/list/active")
      .pipe(
        tap((res) => {}),
        catchError(this.handleError)
      );
  }

  getBranchById(branchId) {
    return this.http
      .get<any>(
        environment.serverURL + "branch/v1/getBranchDetails/" + branchId
      )
      .pipe(
        tap((res) => {}),
        catchError(this.handleError)
      );
  }

  addBranch(data) {
    return this.http.post<any>(environment.serverURL + "branch/v1/", data).pipe(
      tap((res) => {}),
      catchError(this.handleError)
    );
  }

  updateBranch(newBranch) {
    if (newBranch.logo) {
      return of(newBranch).pipe(
        mergeMap((updatedData) =>
          this.s3Service.uploadFileIfPresent(updatedData, "logo")
        ),
        switchMap((updatedData) => {
          return this.http
            .put<any>(
              environment.serverURL + "branch/v1/updateBranch",
              updatedData
            )
            .pipe(catchError(this.handleError));
        })
      );
    } else {
      return this.http
        .put<any>(environment.serverURL + "branch/v1/updateBranch", newBranch)
        .pipe(catchError(this.handleError));
    }
  }

  deleteBranchLogofromS3Bucket(id) {
    return this.http.delete(
      `${environment.serverURL}branch/v1/file/deleteCustomerLogo/${id}`
    );
  }

  deleteBranch(branchId) {
    return this.http.delete(
      `${environment.serverURL}branch/v1/deleteBranch/${branchId}`
    );
  }
}
